import { VStack, HStack, Box, Stack } from '@chakra-ui/react';
import { slidesData } from 'constants/1C/slidesData';
import { slidesStyles } from 'constants/1C/slidesStyles';
import ExspertiseCard from '~entities/ExspertiseCard';
import SliderControls from 'features/SliderControls';
import React, { useState } from 'react';
import BlockHeader from 'shared/BlockHeader';
import Slider from 'shared/Slider';
import { useSwiperControls } from 'shared/hooks/useSwiperControls';

const Expertise = () => {
  const [swiper, setSwiper] = useState();
  const { slideNext, slidePrev } = useSwiperControls(swiper);
  return (
    <Stack w="full" align="start" spacing={{ laptop: '48px', tablet: '32px', mobile: '24px' }}>
      <VStack w="full" align="start" spacing="24px">
        <HStack w="full" justify="space-between" align="end">
          <BlockHeader>
            Экспертиза ASTON
            <br />в 1С-разработке
          </BlockHeader>
          <SliderControls slideNext={slideNext} slidePrev={slidePrev} />
        </HStack>
      </VStack>
      <Box w="100%" overflow="hidden">
        <Slider
          mousewheel={false}
          controller={swiper}
          setController={setSwiper}
          slidesData={slidesData}
          SlideComponent={ExspertiseCard}
          slideStyle={slidesStyles}
        />
      </Box>
    </Stack>
  );
};
export default Expertise;
