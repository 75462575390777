import c1_beats from 'assets/1c/logos/logo_1c_beats.png';
import c1_fresh from 'assets/1c/logos/logo_1c_fresh.png';
import c1 from 'assets/1c/logos/logo_1c.svg';

export const slidesData = [
  {
    src: c1_beats,
    title: `1С:ERP 2.0`,
    text: `Комплексное решение для автоматизации управления предприятием. Содержит инструментарий для ведения бухгалтерского, налогового и складского учета, управления продажами, закупками и др.`,
  },
  {
    src: c1,
    title: `1С:ЗУП КОРП`,
    text: `Комплексное решение для автоматизации управления персоналом. Позволяет решать задачи кадрового планирования, охраны труда, оценки и подбора кадров, учета ДМС и медосмотров и др.`,
  },
  {
    src: c1,
    title: `1С:Конвертация данных`,
    text: `Конфигурация, которая позволяет решить вопрос переноса данных из одной базы в другую, а также преобразовать информацию внутри одной базы`,
  },
  {
    src: c1_fresh,
    title: `1С:Fresh`,
    text: `Подсистема, которая обеспечивает онлайн-доступ к прикладным решениям на платформе «1С:Предприятие». Взаимодействие основано на модели SaaS`,
  },
  {
    src: c1_beats,
    title: `1С:Битрикс`,
    text: `Автоматизированная система разработки и управления веб-проектами, предназначенная для предприятий любого масштаба и сфер деятельности`,
  },
];
