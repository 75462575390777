const project1cCardStyle = {
  iconProps: {
    p: { mobile: '19px 12px', laptop: '22px 11px' },
    h: { mobile: '44px', laptop: '72px' },
    imageProps: {
      w: { mobile: '32px', laptop: '51px' },
    },
  },
  descriptionProps: {
    p: { mobile: '4px 12px 12px 4px', tablet: '4px 12px 8px 4px', laptop: '8px' },
    textProps: {
      ms: { mobile: '12px', tablet: '20px', laptop: '32px' },
    },
  },
};

export const layoutConfig1C = {
  0: {
    gridColumn: '1/3 span',
    gridRow: 1,
    bgColor: 'gray',
    logoProps: {
      display: { mobile: 'none', tablet: 'flex' },
      p: '60px',
      left: { tablet: '50px', laptop: '100px' },
      top: { tablet: '50px', laptop: '100px' },
      boxSize: { tablet: '200px', laptop: '300px' },
    },
    ...project1cCardStyle,
    descriptionProps: {
      p: {
        mobile: '4px 12px 12px 4px',
        tablet: '4px 12px 8px 4px',
        laptop: '8px 20px 8px 8px',
        desktop: '8px',
      },
      textProps: {
        ms: { mobile: '12px', tablet: '20px', laptop: '32px' },
      },
    },
  },
  1: {
    gridRow: 2,
    gridColumn: 1,
    bgColor: 'gray',
    logoProps: {
      display: { mobile: 'none', tablet: 'flex' },
      boxSize: '200px',
      p: '40px',
      bottom: '0px',
      left: 'calc(50% - 100px)',
      top: '450px',
    },
    ...project1cCardStyle,
    descriptionProps: {
      p: { mobile: '4px 12px 12px 4px', tablet: '4px 12px 8px 4px', laptop: '8px' },
      textProps: {
        ms: { mobile: '12px', tablet: '8px', laptop: '32px' },
      },
    },
  },
  2: {
    gridRow: 2,
    gridColumn: '2 span',
    bgColor: '#E6E9EE',
    justify: 'end',
    imageProps: {
      objectFit: { mobile: 'contain', tablet: 'cover' },
      boxSize: { mobile: 'auto', tablet: 'full' },
      pb: { mobile: '50px', tablet: '0px' },
    },
    logoProps: {
      display: 'none',
    },
    ...project1cCardStyle,
  },
  3: {
    gridRow: 3,
    gridColumn: '1/3 span',
    bgColor: '#F5F5E6',
    logoProps: {
      display: { mobile: 'none', tablet: 'flex' },
      p: '60px',
      left: { tablet: '50px', laptop: '100px' },
      top: { tablet: '50px', laptop: '100px' },
      boxSize: { tablet: '200px', laptop: '300px' },
    },
    ...project1cCardStyle,
  },
};
