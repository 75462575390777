import { VStack, Text, Button, Grid } from '@chakra-ui/react';
import { advantageCardsStyles } from 'constants/1C/advantagesCardsStyles';
import { advantagesCardsData } from 'constants/1C/data';
import { goToForm } from 'shared/lib/goToForm';
import React from 'react';
import AdvantageCard from '~entities/AdvantageCard';

const Greetings1C = () => {
  return (
    <VStack w="full" align="start" spacing={{ laptop: '200px', tablet: '120px', mobile: '190px' }}>
      <VStack w="full" align="start" spacing={{ laptop: '80px', tablet: '64px', mobile: '48px' }}>
        <Text as="h1">
          1С-решения для <br /> автоматизации <br /> бизнеса
        </Text>
        <Button onClick={goToForm}>Оставить заявку</Button>
      </VStack>
      <Grid
        w="full"
        templateColumns={{ xxl: 'repeat(3,1fr)', sm: 'repeat(2,1fr)', mobile: '1fr' }}
        templateRows={{ xxl: 'auto', sm: 'repeat(2,auto)' }}
        gridGap={{ laptop: '20px', sm: '16px', mobile: '12px' }}
        placeItems="center"
      >
        {advantagesCardsData?.map((val, i) => (
          <AdvantageCard key={val.title} {...val} {...advantageCardsStyles[i]} />
        ))}
      </Grid>
    </VStack>
  );
};
export default Greetings1C;
