import { Button, VStack } from '@chakra-ui/react';
import { serviceCardStyle } from 'constants/1C/serviceCardsStyles';
import { servicesData } from 'constants/1C/servicesData';
import React from 'react';
import BlockHeader from 'shared/BlockHeader';
import ServiceCard from '~entities/ServiceCard1C';
import { goToForm } from 'shared/lib/goToForm';

const Services1C = () => {
  return (
    <VStack w="full" align="start" spacing={{ mobile: '24px', tablet: '48px' }}>
      <BlockHeader>
        ASTON оказывает полный цикл
        <br />
        услуг по 1С-разработке
      </BlockHeader>
      <VStack w="full" spacing={{ laptop: '40px', tablet: '24px', mobile: '20px' }}>
        <VStack spacing="20px">
          {servicesData?.map((val, i) => (
            <ServiceCard key={val.title} {...val} {...serviceCardStyle[i]} />
          ))}
        </VStack>
        <Button size="secondary" w="full" onClick={goToForm}>
          Оставить заявку
        </Button>
      </VStack>
    </VStack>
  );
};
export default Services1C;
