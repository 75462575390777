import React from 'react';

import Expertise from 'widgets/Expertise';
import Greetings from 'widgets/Greetings1C';
import Services1C from 'widgets/Services1C/Services1C';
import ContactUs from 'widgets/ContactUs';
import Projects from 'widgets/Projects';
import Wrapper from 'shared/Wrapper';

import { schema1C } from 'constants/ContactForm/validateScheme';
import { placeholders1C } from 'constants/ContactForm/constants';
import projectsData from 'constants/1C/projects/data';

const Page1C = () => {
  return (
    <Wrapper>
      <Greetings />
      <Expertise />
      <Services1C />
      <Projects {...projectsData} />
      <ContactUs validationSchema={schema1C} placeholders={placeholders1C} />
    </Wrapper>
  );
};

export default Page1C;
