import React from 'react';

import BlockHeader from 'shared/BlockHeader';
import { goToForm } from 'shared/lib/goToForm';

import { layoutConfig1C } from 'constants/1C/projects/layoutConfigs';
import { allProjects1C } from 'constants/1C/projects/all';

const projectsData = {
  HeaderRender: <BlockHeader>Кейсы</BlockHeader>,
  projectsData: allProjects1C,
  projectsConfig: layoutConfig1C,
  buttonText: 'Оставить заявку',
  onButtonClick: goToForm,
};

export default projectsData;
