export const advantageCardsStyles = {
  0: {
    bgColor: 'bg.grayLight',
  },
  1: {
    bgColor: 'bg.white',
    border: '1.6px solid',
    borderColor: 'text.gray',
    iconStyle: {
      bgColor: 'bg.gray',
    },
  },
  2: {
    bgColor: 'bg.neonLight',
    gridRow: { xxl: 'auto', laptop: '2' },
    gridColumn: { xxl: 'auto', sm: '2 span' },
    textStyle: {
      maxW: { tablet: '400px', laptop: 'fit-content' },
    },
    headStyle: {
      _after: {
        content: `'.'`,
        color: 'text.white',
      },
    },
  },
};
