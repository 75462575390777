export const serviceCardStyle = [
  {
    bgColor: 'bg.white',
    border: '1.6px solid',
    borderColor: 'text.gray',
  },
  {
    bgColor: 'bg.gray',
  },
  {
    bgColor: 'bg.neonLight',
  },
  {
    bgColor: 'bg.gray',
  },
  {
    bgColor: 'text.white',
    border: '1.6px solid',
    borderColor: 'text.gray',
  },
  {
    bgColor: 'bg.black',
    color: 'text.white',
  },
];
