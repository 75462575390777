import confs from 'assets/1c/services/confs.svg';
import integrations from 'assets/1c/services/integrations.svg';
import modif from 'assets/1c/services/modif.svg';
import optimization from 'assets/1c/services/optimization.svg';
import change from 'assets/1c/services/change.svg';
import attraction from 'assets/1c/services/attraction.svg';

export const servicesData = [
  {
    title: `Разработка конфигураций
		1С с нуля`,
    text: `Разрабатываем новые конфигурации в соответствии с бизнес-процессами компании, чтобы устранить пробелы в работе типовых конфигураций`,
    image: confs,
  },
  {
    title: `Разработка интеграций
		с различными системами`,
    text: `Разрабатываем механизм, который позволяет объединить 1С с бизнес-приложениями и инфраструктурой компании`,
    image: integrations,
  },
  {
    title: `Доработка типовых
		продуктов 1С`,
    text: `Добавляем необходимые функциональные модули к типовым продуктам 1С, чтобы оптимизировать бизнес-процессы`,
    image: modif,
  },
  {
    title: `Привлечение
		разработчиков 1С`,
    text: `Усиливаем вашу команду разработчиками, аналитиками, тестировщиками и другими специалистами ASTON`,
    image: attraction,
  },
  {
    title: `Оптимизация
		и тестирование работы 1С`,
    text: `Определяем и исправляем ошибки в работе системы, обновляем базу и проверяем корректность интерграции`,
    image: optimization,
  },
  {
    title: `Импортозамещение иностранного ПО на 1С`,
    text: `Оцениваем ваши системы и бизнес-процессы, проектируем функциональную и инфраструктурную миграцию и выполняем комплексный переход`,
    image: change,
  },
];
