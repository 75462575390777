import React, { memo } from 'react';
import { Stack } from '@chakra-ui/react';
const BlockWrapper = ({ children = null, ...props }) => {
  return (
    <Stack w="full" spacing={{ laptop: '48px', mobile: '32px' }} {...props}>
      {children}
    </Stack>
  );
};
export default memo(BlockWrapper);
