import { VStack, Text, Center, Image } from '@chakra-ui/react';
import React from 'react';
import BlockHeader from 'shared/BlockHeader';

export const AdvantageCard = ({ icon, title, text, iconStyle, headStyle, textStyle, ...props }) => {
  return (
    <VStack
      w="full"
      h={{ xxl: '460px', laptop: '440px', mobile: '340px' }}
      spacing={{ xxl: '10px', mobile: '44px' }}
      align="start"
      borderRadius={{ laptop: '48px', tablet: '40px', mobile: '24px' }}
      p={{ laptop: '12px', mobile: '8px' }}
      boxSizing="border-box"
      {...props}
    >
      <Center
        boxSize={{ laptop: '72px', tablet: '56px', mobile: '44px' }}
        borderRadius="full"
        bgColor="bg.white"
        flexShrink={0}
        {...iconStyle}
      >
        <Image src={icon} objectFit="contain" />
      </Center>
      <VStack
        px={{ mobile: '20px', tablet: '24px' }}
        spacing={{ xxl: '10px', laptop: '20px', tablet: '16px', mobile: '12px' }}
        boxSizing="border-box"
        align="start"
      >
        <BlockHeader as="h4" {...headStyle}>
          {title}
        </BlockHeader>
        <Text textStyle="h1" {...textStyle}>
          {text}
        </Text>
      </VStack>
    </VStack>
  );
};

export default AdvantageCard;
