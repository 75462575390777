import official from 'assets/1c/icons/official.svg';
import individual from 'assets/1c/icons/individual.svg';
import experienced from 'assets/1c/icons/experienced.svg';

export const advantagesCardsData = [
  {
    icon: official,
    title: `Официальный
		партнер 1С`,
    text: `ASTON является официальным партнером 1С-Франчайзинг. Наши эксперты обеспечат комплексное внедрение решений, ПО и лицензии 1С`,
  },
  {
    icon: individual,
    title: `Индивидуальный подход`,
    text: `Мы предлагаем индивидуальную разработку с учетом ваших бизнес-целей и интеграцию в действующий системный ландшафт`,
  },
  {
    icon: experienced,
    title: `Опытные
		специалисты`,
    text: `Готовы решить задачу любой сложности – от разработки конфигураций с нуля до перехода на 1С с иностранного ПО и сервисов`,
  },
];
