import { VStack, Text, Box, Grid } from '@chakra-ui/react';
import React, { memo } from 'react';

const ServiceCard1C = ({ title, text, image, ...props }) => {
  return (
    <Grid
      gap="24px"
      w="full"
      h={{ laptop: '400px', tablet: '300px', mobile: '440px' }}
      bgColor="bg.gray"
      boxSizing="border-box"
      borderRadius={{ laptop: '48px', mobile: '40px' }}
      placeContent="center"
      p={{ laptop: '40px 80px', tablet: '32px', mobile: '40px 24px 24px 24px' }}
      templateColumns={{ mobile: '1fr', tablet: '1.2fr .8fr', laptop: '1.4fr .6fr' }}
      templateRows={{ tablet: '1fr', mobile: '.6fr 1.4fr' }}
      {...props}
    >
      <VStack w="full" gap="20px" align="start" justify="center">
        <Text as="h3">{title}</Text>
        <Text textStyle="h1">{text}</Text>
      </VStack>
      <Box
        w="full"
        h="full"
        boxSizing="border-box"
        gridRow={{ mobile: '2', tablet: 'auto' }}
        bgImage={image}
        bgRepeat="no-repeat"
        bgSize={{ laptop: 'contain', mobile: '100% 100%' }}
        bgPos="center center"
      ></Box>
    </Grid>
  );
};
export default memo(ServiceCard1C);
